<template>
<div style="position: relative; height:100vh;" class="mt-0 mb-4">
      <!-- HERO -->
      <section class="hero is-halfheight">
        <div class="hero-img"></div>
      </section>
      <!-- HERO CONTENT -->
      <div
        class="has-text-centered hero-content"
      >
      <div class="colums is-flex-tablet">
          <div class="column is-6">
            <div class=" m-auto p-4 is-flex is-flex-direction-column" style="width: fit-content; backdrop-filter: blur(4px);background-color: rgb(255 255 255 / 9%) !important;">
            <ul class="pl-3 pt-2">
                        <!-- PHONE -->
            <li>
              <a :href="'tel:+' + siteData.phone" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-phone"></i>
                </span>
                <p class="has-text-white navbar-item">+{{siteData.phone}}</p>
                </a>
              </li>

              <li>
              <a :href="'mailto:' + siteData.email" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-envelope"></i>
                </span>
                  <p class="has-text-white mb-1 navbar-item">{{siteData.email}}</p>
                </a>
              </li>

            <li>
              <a href="tel: +3224" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-location-dot"></i>
                </span>
                <p class="has-text-white mb-1 navbar-item">{{siteData.address}}</p>
                </a>
              </li>
              <li>
              <a href="tel: +3224" class="is-flex is-align-content-center is-align-items-center pl-3 pt-2">
                <span class="icon is-small has-text-primary is-left mr-2">
                  <i class="fas fa-clock"></i>
                </span>
                <p class="has-text-white mb-1 navbar-item">{{siteData.time}}</p>
                </a>
              </li>
              </ul>
            </div>
          </div>
          <div class="column is-6 is-flex is-align-items-center">
            <div class="m-auto">
              <h1 class="title has-text-primary ma is-size-3-mobile is-size-2-tablet is-size-1-fullhd">{{$t('contact_us')}}</h1>
              <p class="has-text-primary">{{$t('contact_us_subtitle')}}</p>
            </div>
          </div>
      </div>
      </div>
    </div>
</template>
<style lang="scss">
    .hero-img{
    background-image: url('~@/assets/img/hero_1.jpg');
    background-position: 85%;
    background-position-y: 80%;
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    width: 100vw;
    max-width: 100%;
}
.hero-content{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 100%;
  transition: 0.125s ease all;
  .columns{
      .column{
          padding: 2rem;
      }
  }
}
</style>
<script>
import { getSiteData } from "../../firebase/FirestoreAPI";

export default {
  data() {
    return{
      siteData: '',
    }
  },
  methods:{
           async getSiteData() {
            let data = await getSiteData()
            if(data) {
              this.siteData = data
            }
        },
  },
  mounted() {
    this.getSiteData()
  window.scrollTo({
     top: 0,
     left: 0,
   });
  },
}
</script>
